<template>
  <b-tabs
    vertical
    content-class="mt-1 col-12 col-md-9 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Business Info</span>
      </template>

      <setting-general :key="updateKey" @reset="resetComponent" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue"
import SettingGeneral from "./SettingGeneral.vue"

export default {
  components: {
    BTabs,
    BTab,
    SettingGeneral
  },
  data() {
    return { updateKey: 0 }
  },

  methods: {
    resetComponent() {
      this.updateKey += 1
    }
  }
}
</script>
